(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/step-indicator/assets/javascripts/step-indicator.js') >= 0) return;  svs.modules.push('/components/ui/step-indicator/assets/javascripts/step-indicator.js');

'use strict';

svs.ui = svs.ui || {};
svs.ui.StepIndicator = class StepIndicator {
  constructor(options) {
    this.el = options.el;
    this.animate = options.animate !== undefined ? options.animate : false;
    this.showDone = options.showDone !== undefined ? options.showDone : false;
    this.isClickable = options.isClickable || false;
    if (this.isClickable) {
      this.initEvents();
    }
  }
  initEvents() {
    this.el.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      const target = this.findClickedEl(e.target, 'step-indicator-step');
      if (target) {
        this.itemClicked(target);
      }
    });
  }
  itemClicked(itemEl) {
    const allSteps = this.el.querySelectorAll('.step-indicator-step');
    const oldActiveItem = this.el.querySelector('.step-indicator-step-active');
    itemEl.querySelector('.step-indicator-step-active-old-content').innerHTML = oldActiveItem.querySelector('.step-indicator-step-content').innerHTML;
    let oldIndex = 0;
    let itemIndex = 0;
    for (let i = 0, length = allSteps.length; i < length; i++) {
      const currentStep = allSteps[i];
      if (currentStep === oldActiveItem) {
        oldIndex = i;
      } else if (currentStep === itemEl) {
        itemIndex = i;
      }
    }
    if (this.animate) {
      const animationEndEvent = () => {
        itemEl.removeEventListener('transitionend', animationEndEvent);
        oldActiveItem.classList.remove('step-indicator-step-deactivating');
        itemEl.classList.remove('step-indicator-step-activating');
        itemEl.classList.add('step-indicator-step-active');
      };
      itemEl.addEventListener('transitionend', animationEndEvent);
      const startX = oldActiveItem.offsetLeft - itemEl.offsetLeft - (oldIndex === 0 ? 19 : 0);
      const activeItemContainer = itemEl.querySelector('.step-indicator-step-active-container');
      activeItemContainer.style.width = "".concat(oldActiveItem.offsetWidth + (oldIndex === 0 ? 21 : 0), "px");
      activeItemContainer.style.transform = "translate3d(".concat(startX, "px, 0, 0)");
      setTimeout(() => {
        if (this.showDone) {
          let delayCounter = 0;
          for (let j = oldIndex; j < itemIndex; j++) {
            allSteps[j].classList.add('step-indicator-step-done');
            allSteps[j].querySelector('.icon').style.transitionDelay = "".concat(delayCounter * 0.2 + 0.2, "s");
            delayCounter++;
          }
        }
        oldActiveItem.classList.remove('step-indicator-step-active');
        oldActiveItem.classList.add('step-indicator-step-deactivating');
        itemEl.classList.add('step-indicator-step-activating');
        activeItemContainer.style.width = "".concat(itemEl.offsetWidth, "px");
        activeItemContainer.style.transform = 'translate3d(0, 0, 0)';
      }, 20);
    } else {
      oldActiveItem.classList.remove('step-indicator-step-active');
      itemEl.classList.add('step-indicator-step-active');
      if (this.showDone) {
        for (let j = oldIndex; j < itemIndex; j++) {
          allSteps[j].classList.add('step-indicator-step-done');
        }
      }
    }
  }
  selectItemByIndex(index) {
    const items = this.el.querySelectorAll('.step-indicator-step');
    if (index < items.length) {
      this.itemClicked(items[index]);
    }
  }
  selectNextItem() {
    const activeItem = this.el.querySelector('.step-indicator-step-active');
    if (activeItem) {
      const nextItem = activeItem.nextElementSibling;
      if (nextItem) {
        this.itemClicked(nextItem);
      }
    }
  }

  findClickedEl(el, className) {
    do {
      if (el.classList.contains(className)) {
        return el;
      }
      el = el.parentElement;
    } while (el !== null);
  }
};

 })(window);