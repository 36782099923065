(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/step-indicator/views/step_partial.js') >= 0) return;  svs.modules.push('/components/ui/step-indicator/views/step_partial.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.ui=_svs.ui||{};
_svs.ui.step_indicator=_svs.ui.step_indicator||{};
_svs.ui.step_indicator.partials=_svs.ui.step_indicator.partials||{};
svs.ui.step_indicator.partials.step = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " step-indicator-step-active";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":93},"end":{"line":1,"column":102}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"step-indicator-step"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":109}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"step-indicator-step-active-container\">\n    <div class=\"step-indicator-step-active-old-content\"></div>\n  </div>\n  <div class=\"step-indicator-step-content\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":56}}}) : helper))) != null ? stack1 : "")
    + "</div>\n  <div class=\"step-indicator-step-content-active\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":63}}}) : helper))) != null ? stack1 : "")
    + "</div>\n  <div class=\"step-indicator-step-icon\">\n    <span class=\"step-indicator-step-icon-container\">\n      "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"color":"fc-green","size":"300","icon":"checkmark"},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":64}}}))
    + "\n    </span>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['ui-step-indicator-step'] = svs.ui.step_indicator.partials.step;
})(svs, Handlebars);


 })(window);